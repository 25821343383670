function initMap() {
    var bounds = new google.maps.LatLngBounds();

    var sedi = [
        ["Mecc Alte SpA", 45.519508, 11.452333],
        ["Mecc Alte Power Products", 45.491469, 11.439034],
        ["Mecc Alte Portable", 45.408063, 11.240254],
        ["Zanardi", 45.520743, 11.461111],
        ["Veco SpA", 45.235532, 11.388735],
        ["Mecc Alte International S.A.", 45.804990, 0.140001],
        ["Mecc Alte U.K. LTD", 52.678426, -0.737617],
        ["Mecc Alte Generatoren GmbH", 50.894929, 7.053839],
        ["Mecc Alte España S.A.", 38.078250, -0.738456],
        ["Mecc Alte (F.E.) PTE LTD", 1.330509, 103.692007],
        ["Mecc Alte Inc.", 42.347035, -88.248277],
        ["Mecc Alte Alternators PTY LTD", -34.840586, 138.585145],
        ["Mecc Alte Alternator (Haimen) Ltd", 31.883437, 121.213010],
        ["Mecc Alte India PVT LTD", 18.659463, 74.131005],
    ];

    var map = new google.maps.Map(document.getElementById('map'), {
        scrollwheel: false,
        styles: [{
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
                "color": "#e9e9e9"
            }, {
                "lightness": 17
            }]
        }, {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [{
                "color": "#f5f5f5"
            }, {
                "lightness": 20
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 17
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 29
            }, {
                "weight": 0.2
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 18
            }]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{
                "color": "#ffffff"
            }, {
                "lightness": 16
            }]
        }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
                "color": "#f5f5f5"
            }, {
                "lightness": 21
            }]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
                "color": "#dedede"
            }, {
                "lightness": 21
            }]
        }, {
            "elementType": "labels.text.stroke",
            "stylers": [{
                "visibility": "on"
            }, {
                "color": "#ffffff"
            }, {
                "lightness": 16
            }]
        }, {
            "elementType": "labels.text.fill",
            "stylers": [{
                "saturation": 36
            }, {
                "color": "#333333"
            }, {
                "lightness": 40
            }]
        }, {
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [{
                "color": "#f2f2f2"
            }, {
                "lightness": 19
            }]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#fefefe"
            }, {
                "lightness": 20
            }]
        }, {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [{
                "color": "#fefefe"
            }, {
                "lightness": 17
            }, {
                "weight": 1.2
            }]
        }]
    });

    var infowindow = new google.maps.InfoWindow();
    // var icona = base  + 'images/marker.png';
    var icona = "/_Resources/Static/Packages/Proteinic.MeccAlte/Images/marker.png";

    var marker, i;

    for (i = 0; i < sedi.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(sedi[i][1], sedi[i][2]),
            map: map,
            icon: icona,
            zIndex: 100
        });

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                infowindow.setContent(sedi[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));

        bounds.extend(marker.position);
    }

    map.fitBounds(bounds);
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('map')) {
		initMap();
	}
});
